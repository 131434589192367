<template>
  <a-card :bordered="false">
    <div class="content">
      <div class="chat-box">
        <div class="left">
          <div class="top">
            <div>消息列表（{{ contactsList.total }}条消息，{{ contactsList.list | count }}条未读）</div>
            <div @click="searchDia">
              <a-icon type="search" />
              <a-modal
                :width="800"
                title="搜索"
                :footer="null"
                :visible="searchVisible"
                @cancel="searchHandleCancel"
              >
                <div class="table-page-search-wrapper">
                  <a-form-model layout="inline">
                    <a-row :gutter="48">
                      <a-col :md="12" :sm="24">
                        <a-form-model-item label="快递员">
                          <a-input v-model="queryParam.courierName" placeholder="请输入快递员姓名" />
                        </a-form-model-item>
                      </a-col>
                      <a-col :md="12" :sm="24">
                        <a-form-model-item label="快递员/收件人电话">
                          <a-input v-model.trim="queryParam.phoneNumber" placeholder="请输入电话号码" />
                        </a-form-model-item>
                      </a-col>
                      <a-col :md="12" :sm="24">
                        <a-form-model-item label="物流单号">
                          <a-input v-model="queryParam.number" placeholder="请输入物流单号" />
                        </a-form-model-item>
                      </a-col>
                      <a-col :md="12" :sm="24">
                        <a-form-model-item label="跟进状态">
                          <a-select v-model="queryParam.progressStatus" placeholder="请选择跟进状态">
                            <a-select-option value="待跟进">
                              待跟进
                            </a-select-option>
                            <a-select-option value="跟进中">
                              跟进中
                            </a-select-option>
                            <a-select-option value="已完成">
                              已完成
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>
                      <a-col :md="4" :sm="24">
                        <span class="table-page-search-submitButtons">
                          <a-button type="primary" icon="search" @click="queryClick">查询</a-button>
                        </span>
                      </a-col>
                      <a-col :md="4" :sm="24">
                        <span class="table-page-search-submitButtons">
                          <a-button type="primary" icon="reload" @click="clearClick">清空</a-button>
                        </span>
                      </a-col>
                    </a-row>
                  </a-form-model>
                  <div v-if="searchList.list.length!==0">
                    <div class="search-contacts-box">
                      <div
                        class="contacts"
                        v-for="(item, key) of searchList.list"
                        :key="key"
                      >
                        <div class="dot" :class="item.read === 0 ? 'fff' : 'red'"></div>
                        <div class="details" @click="searchSelect(item.number, item.toOpenId)">
                          <div>
                            <div>{{ item.courierName }}</div>
                            <div>{{ item.courierPhone }}</div>
                          </div>
                          <div>
                            <div>物流单号</div>
                            <div>{{ item.number }}</div>
                          </div>
                          <div>
                            <div>收件人电话</div>
                            <div>{{ item.phoneNumber }}</div>
                          </div>
                        </div>
                        <div class="func">
                          <!--                          <a-icon-->
                          <!--                            @click="close(item.number)"-->
                          <!--                            type="close-square"-->
                          <!--                            theme="filled"-->
                          <!--                            style="font-size: 24px;margin-top: 2px"-->
                          <!--                          />-->
                          <div
                            :class="
                              item.progressStatus === '已完成'
                                ? 'color-fff'
                                : item.progressStatus === '跟进中'
                                  ? 'color-green'
                                  : 'color-red'
                            "
                          >
                            {{ item.progressStatus ? item.progressStatus : '待跟进' }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="search-contacts-box">暂无数据</div>
                </div>
              </a-modal>
            </div>
          </div>
          <div class="contacts-box" ref="contactsBox">
            <div
              class="contacts"
              :class="item.number === messageData.to ? 'actions' : null"
              v-for="(item, key) of contactsList.list"
              :key="key"
            >
              <div class="dot" :class="item.read === 0 ? 'fff' : 'red'"></div>
              <div class="details" @click="join(item.number, item.toOpenId)">
                <div>
                  <div>{{ item.courierName }}</div>
                  <div>{{ item.courierPhone }}</div>
                </div>
                <div>
                  <div>物流单号</div>
                  <div>{{ item.number }}</div>
                </div>
                <div>
                  <div>收件人电话</div>
                  <div>{{ item.phoneNumber }}</div>
                </div>
              </div>
              <div class="func">
                <a-icon
                  @click="close(item.number)"
                  type="close-square"
                  theme="filled"
                  style="font-size: 24px;margin-top: 2px"
                />
                <div
                  :class="
                    item.progressStatus === '已完成'
                      ? 'color-fff'
                      : item.progressStatus === '跟进中'
                        ? 'color-green'
                        : 'color-red'
                  "
                >
                  {{ item.progressStatus ? item.progressStatus : '待跟进' }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center">
          <div class="top">
            <span>对话框</span>
            <span @click="producedUrl" v-show="messageData.toOpenId" style="float: right;color: #1890FF;cursor: pointer">生成链接</span>
            <a-modal title="生成链接" :visible="visible" :footer="null" @cancel="handleCancel">
              <a-form-model :model="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" labelAlign="left">
                <a-form-model-item label="输入单号/收件人电话：">
                  <div class="copy-input">
                    <a-input v-model.trim="number" placeholder="请输入单号/收件人电话" />
                    <a-button type="primary" @click="onSearch">生成链接</a-button>
                  </div>
                </a-form-model-item>
                <a-form-model-item label="链接" :wrapper-col="{ span: 24 }">
                  <div class="copy-input">
                    <a-textarea :rows="5" class="remarks" ref="urlBox" v-model="link"></a-textarea>
                    <a-button type="primary" @click="copy">复制链接</a-button>
                  </div>
                </a-form-model-item>
              </a-form-model>
            </a-modal>
          </div>
          <div class="message" ref="messageBox" @scroll="scrollEvent">
            <!--            <div v-show="!loading&&messageList>0" class="message-tip">{{ pageData.page < pages?"上拉加载更多":"没有更多了" }}</div>-->
            <a-spin :spinning="loading" size="small" />
            <div
              v-for="(item, key) of messageList"
              class="message-info"
              :key="key"
              :class="item.to === messageData.to ? 'form' : 'to'"
            >
              <div>
                {{ item.time }}
              </div>
              <div v-html="noClick(item.content)"></div>
            </div>
          </div>
          <div class="send" v-show="messageData.toOpenId">
            <a-textarea
              class="a-textarea"
              v-model="messageData.content"
              placeholder="请输入文字信息"
              :rows="4"
              resize="none"
            />
            <a-button type="primary" @click="send()" v-show="messageData.content">发送</a-button>
          </div>
        </div>
        <div class="right">
          <a-tabs default-active-key="1" class="tabs" size="large">
            <a-tab-pane key="1" tab="快递单详情">
              <div class="express">
                <div>
                  <div>
                    <div>物流单号:</div>
                    <div>{{ expressBillDetails.id }}</div>
                  </div>
                  <div>
                    <div>快递员:</div>
                    <div>{{ expressBillDetails.courierName }}</div>
                  </div>
                  <div>
                    <div>快递员电话:</div>
                    <div>{{ expressBillDetails.courierPhone }}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>收件人:</div>
                    <div>{{ expressBillDetails.recipient }}</div>
                  </div>
                  <div>
                    <div>收件人电话:</div>
                    <div>{{ expressBillDetails.phoneNumber }}</div>
                  </div>
                  <div>
                    <div>收件人地址:</div>
                    <div>{{ expressBillDetails.address }}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>是否紧急:</div>
                    <div :class="expressBillDetails.urgent === '紧急' ? 'textRed' : ''">
                      {{ expressBillDetails.urgent ? expressBillDetails.urgent : '' }}
                    </div>
                  </div>
                  <div>
                    <div>收寄日期:</div>
                    <div>{{ expressBillDetails.deliveryDate ? expressBillDetails.deliveryDate : '' }}</div>
                  </div>
                  <div>
                    <div>处理日期:</div>
                    <div>{{ expressBillDetails.updateTime ? expressBillDetails.updateTime : '' }}</div>
                  </div>
                  <div>
                    <div>处理动作:</div>
                    <div>{{ expressBillDetails.lastAction ? expressBillDetails.lastAction : '' }}</div>
                  </div>
                  <div>
                    <div>处理机构:</div>
                    <div>{{ expressBillDetails.orgfullname ? expressBillDetails.orgfullname : '' }}</div>
                  </div>
                  <div>
                    <div>详细信息:</div>
                    <div>{{ expressBillDetails.description ? expressBillDetails.description : '' }}</div>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="状态标记" force-render>
              <div class="status-tag">
                <div class="feedback">
                  <div>
                    <span style="font-weight: bold">反馈时间：</span>
                    {{expressBillDetails.feedbackTime}}
                  </div>
                  <div style="word-wrap: break-word;word-break: normal;" >
                    <span style="font-weight: bold">快递员标记状态：</span>
                    {{ expressBillDetails.feedbackStatusDesc }}
                  </div>
                  <div style="word-wrap: break-word;word-break: normal;">
                    <span style="font-weight: bold">快递员反馈信息：</span>
                    {{ expressBillDetails.feedbackRemark }}
                  </div>
                </div>
                <a-form-model :model="typeForm" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" labelAlign="left">
                  <a-form-model-item label="请选择跟进状态">
                    <a-select v-model="typeForm.progressStatus" placeholder="请选择跟进状态">
                      <a-select-option value="待跟进">
                        待跟进
                      </a-select-option>
                      <a-select-option value="跟进中">
                        跟进中
                      </a-select-option>
                      <a-select-option value="已完成">
                        已完成
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="微信营销状态" :label-col="{ span: 24 }">
                    <a-radio-group v-model="typeForm.statusId">
                      <a-radio v-for="(item, key) of statusList" :value="item.id" :key="key" :style="radioStyle">
                        {{ item.name }}
                      </a-radio>
                    </a-radio-group>
                  </a-form-model-item>
                  <a-form-model-item label="备注" :wrapper-col="{ span: 24 }">
                    <a-input class="remarks" v-model="typeForm.remark" type="textarea" placeholder="请输入备注信息" />
                  </a-form-model-item>
                  <a-form-model-item :wrapper-col="{ span: 24 }">
                    <a-button type="primary" block @click="submit" style="margin-top: 20px">
                      保存
                    </a-button>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
  </a-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'ChatView',
  data() {
    return {
      queryParam: {
        customerService: '',
        courierName: '',
        number: '',
        phoneNumber: '',
        progressStatus: ''
      },
      form: {},
      typeForm: {
        statusId: null,
        remark: '',
        progressStatus: ''
      },
      statusList: [],
      contactsList: {
        list: []
      },
      searchList: {
        list: []
      },
      searchVisible: false,
      messageData: {
        to: '',
        from: null,
        content: '',
        time: null,
        toOpenId: null
      },
      number: null,
      link: null,
      pageData: {
        page: 1,
        size: 10
      },
      pages: null,
      loading: false,
      messageList: [],
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },
      visible: false,
      // stompClient: null,
      url: process.env.VUE_APP_API_DELIVERY_BASE_URL,
      expressBillDetails: {},
      flag: true
    }
  },
  computed: {
    ...mapState({
      id: state => state.user.info.id,
      realName: state => state.user.info.realName,
      stompClient: state => state.user.stompClient
    }),
    noClick() {
      return function(value) {
        const aHrefRegExp = /<a[^>]+href=['"]([^'"]*)['"]>/gi
        if (value.search(aHrefRegExp) !== -1) {
          return value.replace(/<a[^>]+href=['"]([^'"]*)['"]/gi, "$& onclick='return false'")
        } else {
          return value
        }
      }
    }
  },
  filters: {
    count(value) {
      let count = 0
      value.forEach(elem => {
        if (elem.read > 0) {
          count += 1
        }
      })
      return count
    }
  },
  watch: {
    // contactsList(n, o) {
    //   if (n.list.length - o.list.length === 1) {
    //     this.$nextTick(() => {
    //       this.join(n.list[0].number, n.list[0].toOpenId)
    //     })
    //   }
    // }
  },
  mounted() {
    this.chatList()
    this.findStatusList()
    this.messageData.from = this.id
    this.$stomp.subscribes['/user/chat'] = this.$stomp.subscribes['/user/chat/msgListNotice'] = this
  },
  methods: {
    ...mapMutations({
      setWait: 'SET_WAIT'
    }),
    scrollEvent(e) {
      if (this.pageData.page < this.pages) {
        if (e.target.scrollTop === 0 && e.target.scrollHeight > 400) {
          this.pageData.page += 1
          this.loading = true
          this.axios
            .get(`${this.url}/delivery-cloud/history`, {
              params: { number: this.messageData.to, page: this.pageData.page, size: this.pageData.size }
            })
            .then(res => {
              if (res.status !== 200) {
                throw new Error(res.message)
              }
              const msg = res.body.list.sort((t1, t2) => {
                return t2.time < t1.time ? 1 : -1
              })
              const old = e.target.scrollHeight
              this.messageList.unshift(...msg)
              this.$nextTick(() => {
                const news = e.target.scrollHeight
                const ele = this.$refs.messageBox
                ele.scrollTop = news - old
              })
            })
            .catch(e => {})
            .finally(() => {
              this.loading = false
            })
        }
      }
    },
    mailDetail(id) {
      const url = `${this.url}/delivery-cloud/mail/detail`
      this.axios
        .get(url, { params: { number: id } })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.expressBillDetails = res.body
          this.typeForm.statusId = res.body.statusId ? res.body.statusId : null
          this.typeForm.remark = res.body.remark ? res.body.remark : null
          this.typeForm.progressStatus = res.body.progressStatus ? res.body.progressStatus : null
        })
        .catch(e => {
          console.log(e.message)
        })
    },
    findStatusList() {
      const url = `${this.url}/delivery-cloud/mail/findStatusList`
      this.axios
        .get(url)
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.statusList = res.body
        })
        .catch(e => {
          console.log(e.message)
        })
    },
    searchDia() {
      this.searchVisible = true
    },
    queryClick() {
      this.queryParam.customerService = this.id
      // this.messageData.to = null
      // this.messageList = []
      // this.expressBillDetails = {}
      const url = `${this.url}/delivery-cloud/findServiceChatList`
      this.axios
        .get(url, { params: this.queryParam })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.searchList = res.body
        })
        .catch(e => {
          console.log(e.message)
        })
    },
    clearClick() {
      this.queryParam = this.$options.data().queryParam
    },
    chatList() {
      const url = `${this.url}/delivery-cloud/findServiceChatList`
      this.axios
        .get(url, { params: { customerService: this.id } })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.contactsList = res.body
        })
        .catch(e => {
          console.log(e.message)
        })
    },
    onSearch() {
      const data = { number: this.number, toOpenId: this.messageData.toOpenId }
      this.axios
        .get(`${this.url}/delivery-cloud/generateChatLink`, { params: data })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.$notification.success({
            message: '提示',
            description: res.message
          })
          this.link = res.body
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
    },
    submit() {
      if (!this.typeForm.progressStatus) {
        this.$notification.error({
          message: '提示',
          description: '请选择跟进状态！'
        })
        return
      }
      const index = this.statusList.findIndex(value => {
        return value.id === this.typeForm.statusId
      })
      if (index !== -1) {
        this.typeForm.statusDesc = this.statusList[index].name
      }
      this.typeForm.id = this.messageData.to
      this.typeForm.serviceId = this.id
      this.typeForm.serviceName = this.realName
      this.axios({
        url: `${this.url}/delivery-cloud/mail/updateMailStatus`,
        method: 'post',
        data: this.typeForm,
        transformRequest: [
          function(data) {
            let ret = ''
            for (const it in data) {
              if (data[it]) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
              }
            }
            return ret
          }
        ]
      }).then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.$notification.success({
          message: '提示',
          description: res.message
        })
        // this.typeForm = this.$options.data().typeForm
      }).catch(e => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      })
    },
    producedUrl() {
      this.visible = true
    },
    handleCancel(e) {
      this.visible = false
      this.link = ''
    },
    searchHandleCancel() {
      this.searchVisible = false
    },
    copy() {
      this.$refs.urlBox.$el.select()
      document.execCommand('Copy')
      this.$message.success('已复制到剪贴板！')
    },
    getHistory(id) {
      this.axios.get(`${this.url}/delivery-cloud/history`, { params: { number: id, page: 1, size: 10 } }).then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.messageList = res.body.list.sort((t1, t2) => {
          return t2.time < t1.time ? 1 : -1
        })
        this.scrollTop()
        this.pages = Math.ceil(res.body.total / 10)
      })
    },
    searchSelect(id, toOpenId) {
      const params = { customerService: this.id, number: id }
      this.axios.get(`${this.url}/delivery-cloud/topNumber`, { params }).then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.searchVisible = false
        this.join(id, toOpenId)
      }).catch(e => {
        console.log(e)
      })
    },
    join(id, toOpenId) {
      this.messageData.toOpenId = toOpenId
      this.messageData.to = id
      this.pageData.page = 1
      this.getHistory(id)
      this.readMsg(id)
      this.scrollTop()
      this.mailDetail(id)
      this.typeForm.id = id
    },
    send() {
      // this.messageList.push({ to: 'USER', from: '53', content: '789', time: '10:52' })
      const myDate = new Date()
      const MM = myDate.getMonth() + 1 > 9 ? myDate.getMonth() + 1 : '0' + (myDate.getMonth() + 1)
      const DD = myDate.getDate() > 9 ? myDate.getDate() : '0' + myDate.getDate()
      const HH = myDate.getHours() > 9 ? myDate.getHours() : '0' + myDate.getHours()
      const mm = myDate.getMinutes() > 9 ? myDate.getMinutes() : '0' + myDate.getMinutes()
      const SS = myDate.getSeconds() > 9 ? myDate.getSeconds() : '0' + myDate.getSeconds()
      this.messageData.time = `${myDate.getFullYear()}-${MM}-${DD} ${HH}:${mm}:${SS}`
      this.stompClient.send('/chat', {}, JSON.stringify(this.messageData))
      this.messageList.push({ ...this.messageData })
      console.log(this.messageList)
      this.messageData.content = ''
      this.scrollTop()
    },
    scrollTop() {
      this.$nextTick(() => {
        const ele = this.$refs.messageBox
        if (ele) {
          ele.scrollTop = ele.scrollHeight
        }
      })
    },
    readMsg(number) {
      this.axios.post(`${this.url}/delivery-cloud/readMsg`, { number }).then(res => {
        // console.log(res)
      })
    },
    close(number) {
      this.axios.get(`${this.url}/delivery-cloud/deleteServiceUserMapping`, { params: { number } }).then(res => {
        console.log(res)
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        if (number === this.messageData.to) {
          this.messageList = []
          this.expressBillDetails = {}
          this.typeForm = this.$options.data().typeForm
          this.messageData.toOpenId = ''
          this.messageData.to = ''
        }
      })
    }
  }
}
</script>

<style scoped>
  .chat-box {
    display: flex;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    height: 600px;
  }
  .left {
    width: 390px;
  }
  .right {
    width: 380px;
  }
  .center {
    width: 848px;
    border-right: 1px solid #f0f0f0;
  }
  .contacts-box {
    height: 548px;
    overflow-y: auto;
    border-right: 1px solid #dfdfdf;
  }
  .search-contacts-box{
    height: 400px;
    overflow-y: auto;
    border-right: 1px solid #dfdfdf;
  }
  .contacts-box::-webkit-scrollbar,
  .message::-webkit-scrollbar,
  .express::-webkit-scrollbar,
  .status-tag::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .contacts-box::-webkit-scrollbar-track,
  .message::-webkit-scrollbar-track,
  .express::-webkit-scrollbar-track,
  .status-tag::-webkit-scrollbar-track {
    border-radius: 2px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .contacts-box::-webkit-scrollbar-thumb,
  .message::-webkit-scrollbar-thumb,
  .express::-webkit-scrollbar-thumb,
  .status-tag::-webkit-scrollbar-thumb {
    border-radius: 2px; /*滚动条的圆角*/
    background-color: skyblue;
  }
  .top {
    padding: 10px;
    font-size: 20px;
    border: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
  }
  .contacts {
    padding: 15px 16px;
    background: #f9fafe;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100px;
  }
  .actions {
    background: #e3e9f0;
  }
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  .red {
    background: #e02020;
  }
  .fff {
    background: #c6c8c9;
  }
  .green {
    background: #1bb300;
  }
  .color-red {
    color: #e02020;
  }
  .color-fff {
    color: #c6c8c9;
  }
  .color-green {
    color: #1bb300;
  }
  .details {
    cursor: pointer;
    flex: auto;
    padding: 0 20px;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
  .details > div {
    display: flex;
  }
  .details > div > div:first-child {
    width: 100px;
  }
  .details > div:first-child {
    font-size: 22px;
    line-height: 22px;
    color: #333333;
  }
  .details > div:nth-child(n + 2) {
    font-size: 16px;
    line-height: 16px;
    color: #666666;
  }
  .func {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .func > div {
    font-size: 16px;
    line-height: 16px;
  }
  .message {
    height: 400px;
    border-bottom: 1px solid #f0f0f0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 30px;
  }
  .message > div {
    margin: 30px 0;
  }
  .message > div > div:nth-child(2) {
    padding: 12px 10px;
    border-radius: 4px;
    margin-top: 10px;
    max-width: 276px;
    box-sizing: border-box;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
    width: auto;
    display: inline-block !important;
    /*white-space: normal;*/
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre-line;
  }
  .message-info a {
    pointer-events: none;
  }
  .send {
    height: 130px;
    text-align: right;
  }
  .to {
    align-self: flex-start;
  }
  .form > div:nth-child(2) {
    background: #dadada;
    text-align: left;
  }
  .form {
    align-self: flex-end;
    text-align: right;
  }
  .to > div:nth-child(2) {
    background: #ddf2fc;
  }
  .send .a-textarea {
    resize: none;
    border: none;
  }
  .remarks {
    resize: none;
  }
  /deep/ .ant-tabs-bar {
    margin: 0;
  }
  /deep/ .ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
    box-sizing: border-box;
    padding: 12px 42px;
    font-size: 20px;
  }
  .express {
    height: 548px;
    overflow-y: auto;
  }
  .express > div {
    border-top: 1px solid #f0f0f0;
    padding: 20px 20px;
    font-size: 16px;
  }
  .express > div > div {
    display: flex;
  }
  .express > div > div > div:first-child {
    width: 100px;
    color: #666666;
  }
  .express > div > div > div:last-child {
    width: 100px;
    color: #333333;
    font-weight: 500;
    flex: auto;
  }
  .express > div > div:nth-child(n + 2) {
    margin-top: 15px;
  }
  .status-tag {
    padding: 20px;
    height: 548px;
    overflow-y: auto;
  }
  .copy-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .copy-input > button {
    margin-left: 10px;
  }
  .message-tip {
    display: inline-block;
    border-radius: 5px;
    margin: 5px auto !important;
    background: #dfdfdf;
    padding: 5px;
  }
  .textRed {
    color: #ff0200 !important;
  }
  .feedback{
    border-bottom: 1px solid #dfdfdf;
    padding: 0 0 20px;
    margin-bottom: 20px
  }
  .feedback>div{
    padding: 5px 0;
  }
</style>
